<template>
  <div class="login-wrappper">
    <div class="login-inner">
      <img class="login-img" src="" alt="">

      <div class="form-wrapper">
        <p class="login-tip"><img src="" alt=""> 账号密码登陆</p>

        <el-form :model="form" class="login-form" :rules="rules" ref="ruleForm">
          <p>
            <el-form-item prop="name">
              <el-input class autofocus ref="userInput" v-model.trim="form.name" @keydown.enter="testPwd" placeholder="账号" @focus="resetForm"></el-input>
            </el-form-item>
          </p>
          <p>
            <el-form-item prop="password">
              <el-input type="password" ref="pwdInput" @keydown.enter="submitForm" v-model.trim="form.password" placeholder="密码" @focus="resetForm"></el-input>
            </el-form-item>
          </p>
          <p>
            <el-button type="primary" @click="submitForm">登录</el-button>
          </p>
          <p style="color: #DEDEDE;
    text-align: right;">忘记密码请联系管理员</p>
        </el-form>
      </div>

    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { login as _login } from "@api"
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
export default {
  name: "Login",
  setup () {
    const router = useRouter()
    const form = ref({
      name: "",
      password: ""
    })
    const rules = ref({
      name: [
        { required: true, message: "请输入用户名", trigger: "null" }
      ],
      password: [{ required: true, message: "请输入密码", trigger: "null" }]
    })
    const userInput = ref(null)
    const pwdInput = ref(null)
    const ruleForm = ref(null)
    const testPwd = () => {
      if ('' == form.value.password) {
        pwdInput.value.focus()
      } else {
        submitForm()
      }
    }
    const submitForm = () => {
      ruleForm.value.validate(valid => {
        if (valid) {
          login()
        } else {
          return false;
        }
      });
    }
    const resetForm = () => {
      ruleForm.value.clearValidate();
    }
    const login = () => {
      _login(form.value)
        .then(res => {
          if (res.code === '200') {
            router.push("/");
            localStorage.setItem('accessToken', res.data.token)
            localStorage.setItem('uname', res.data.name)
          } else {
            if (window.innerWidth < 767) {
              return window.weui.toast(res.message, 1000);
            }
            ElMessage.error(res.message)
          }
        }).catch(err => {
          console.log(err)
        })
    }
    onMounted(() => {
      if ('' == form.value.name) {
        userInput.value.focus()
      } else {
        pwdInput.value.focus()
      }
    })

    return {
      form,
      rules,
      userInput,
      pwdInput,
      ruleForm,
      testPwd,
      submitForm,
      resetForm,
      login
    }
  }
};
</script>

<style lang="stylus" scoped>
.top-inner {
  height: 60px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  opacity: 1;
  position: relative;
  z-index: 1000;

  .logo {
    height: 100%;
    vertical-align: middle;
  }

  .title-info {
    width: 342px;
    font-size: 19px;
    font-family: SimHei;
    font-weight: 400;
    line-height: 60px;
    color: rgba(0, 0, 0, 1);
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding-left: 20px;
    border-left: 1px solid rgba(33, 33, 33, 1);
  }
}

.login-img {
  width: 0;
}

.login-form {
  padding: 60px;
}

#bc {
  background: #FAFAFA;
}

.login-wrappper {
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
}

.form-wrapper {
  width: 420px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  border-radius: 5px;
  background: #fff;

  p + p {
    margin-top: 20px;
    color: #fff;
  }

  .el-button--primary {
    width: 100%;
    background: #F62C27;
    border-color: #F62C27;
  }
}

.login-inner {
  height: 100%;
  background: #FAFAFA;
  display: flex;
  align-items: center;
  justify-content: space-around;
  // width: 1200px;
  margin: 0 auto;
  // margin-top: 50px;
  flex: 1;
  width: 100%;
}

.outer-wrapper {
  position: absolute;
  top: 0;
  z-index: 100;
}

.login-tip {
  padding-left: 20px;
  color: #7C7C7C;
  height: 50px;
  line-height: 50px;
  border-bottom: 4px solid rgba(246, 44, 39, 1);

  img {
    width: 18px;
    margin-right: 5px;
    vertical-align: sub;
  }
}

.pic {
  height: 500px;
  width: 500px;
  background-size: cover;
}

.bottom-tip {
  position: fixed;
  bottom: 30px;
  z-index: 1000;
  width: 80%;
  border-top: 1px solid rgba(112, 112, 112, 1);
  left: 0;
  right: 0;
  margin: auto;
  height: 30px;
  line-height: 30px;
}

@media screen and (max-width: 767px) {
  .login-inner {
    background-color #fff;
  }
  .form-wrapper {
    box-shadow none
  }
  .login-img {
    display none
  }
} 

</style>

